<template>
  <div>
    <molecules-hero-header
      class="!bg-gray-10"
      :image-config="{
        img: `${APP_ASSET_BASEURL}edge/static/images/hero/homepage_hero_campaign_v3_1x.webp`,
        class: 'xl:-right-[8%] top-[2.5rem] w-[33.75rem]',
        dimensions: {
          width: 540,
          height: 436
        }
      }"
      outer-min-height="min-h-[36.125rem]"
      title-class="lg:!text-5xl lg:!leading-[3.5rem]"
      sub-title-class="mt-[1.125rem] mb-[0.875rem] md:mt-6"
      main-content-class="lg:max-w-wrapper-sm"
      hide-image-on-mobile
      img-alt="Want more value for your money? Join The Club"
    >
      <template #header>
        Want more value for your money?
        <br class="block md:hidden" />
        <span class="font-extrabold">Join The Club</span>
      </template>

      <template #sub-header>
        And use our

        <img
          class="inline w-36 h-6"
          :src="expertEaseImg"
          alt="Expert ease"
        />

        to <strong>switch and save.</strong>
        
        <div class="grid grid-cols-2 gap-4 mt-3 md:mt-10 md:grid-cols-4 w-full lg:max-w-[34rem]">
          <MoleculesVerticalCard
            v-for="(vertical, verticalKey) in verticalCard"
            :key="verticalKey"
            :vertical="vertical"
            :link-url="vertical?.url"
          />
        </div>
      </template>
    </molecules-hero-header>

    <MoleculesTrustpilot class="py-8 border border-solid border-gray-350 min-h-[5.875rem] bg-white" />

    <AtomsMediaOutlets
      :media-outlets="mediaOutlets"
      image-lazy-load
    />

    <molecules-home-talk-to-us
      inner-wrapper-class="lg:max-w-[58.875rem] lg:relative lg:-left-[0.875rem]"
      class="bg-gray-10"
      :buttons="[
        {
          label: 'COMPARE HEALTH INSURANCE',
          to: VERTICALS.Default.formPage
        }
      ]"
      :card-cta-config="{
        label: VERTICALS.Default.phoneNumber
      }"
      image-lazy-load
    >
      <template #header>
        How our
        <strong class="font-extrabold">Expert<i>Ease</i></strong> could<br />
        save you hundreds.
      </template>

      <template #sub-header>
        At <strong>Compare Club,</strong> our expertise is in getting you
        better value insurance and loans, not to mention lower monthly bills.
        Because we make your life easier, we like to call this our ExpertEase.
        Our specialist teams are here to lend their ExpertEase at every step,
        from comparing products to handling paperwork.
      </template>
    </molecules-home-talk-to-us>

    <molecules-home-saving-plan
      :featured-image-url="`${APP_ASSET_BASEURL}edge/static/images/hero/homepage_tile_v3.webp`"
      :featured-image-dimensions="{
        width: 702,
        height: 1088
      }"
      :verticals="savingPlanItems"
      card-title-class="md:!text-2xl/[2rem]"
      featured-image-wrapper-class="lg:-ml-[4.375rem]"
      image-lazy-load
      card-title-has-icon
      class="bg-white"
      img-alt="Do your household expenses need saving? Here’s how Compare Club’s ExpertEase can help"
    >
      <template #header>
        Do your household expenses need
        <strong class="font-extrabold">saving? </strong><br class="lg:block hidden" />
        Here’s how
        <strong class="font-extrabold">Compare Club’s Expert<i>Ease</i></strong>
        can help.
      </template>

      <template #sub-header>
        We listen to your needs and use our industry experience to help you
        <br class="lg:block hidden" />
        <strong>save money and time</strong> on the things you
        <strong>value most.</strong>
      </template>
    </molecules-home-saving-plan>

    <div class="bg-white px-4 lg:px-0 pt-[2.125rem] pb-20 lg:pt-10">
      <div class="max-w-wrapper-md mx-auto">
        <p class="text-2xl  md:text-4.5xl text-center leading-10 md:leading-[3rem]">
          How
          <strong class="font-extrabold">our experts</strong> have<br />
          <strong class="font-extrabold">helped Australians</strong>
          like you
        </p>

        <LazyMoleculesTrustpilotReviews class="mt-8" />
      </div>
    </div>

    <div class="bg-neutral-50 py-10 lg:py-20 px-4 lg:px-0">
      <div class="max-w-wrapper-lg flex flex-col items-center mx-auto text-center">
        <p class="text-2.5xl md:text-4.5xl mb-6">
          Who are <strong class="font-extrabold">Compare Club?</strong>
        </p>

        <p class="text-lg mb-10 md:mb-9 max-w-[58rem] mx-auto">
          We’re experts in personal finance. Our team has years of experience in their specialist area and new Clubbers <br clas="hidden md:block" />go through rigorous expert training. We do this so we can be single minded in making sure we can find Australian <br clas="hidden md:block" />households the best possible value from some of their biggest expenses.
          <br />
          <br />
          Whether it’s refinancing your home loan or buying life insurance for the first time, we’ve built our business<br clas="hidden md:block" /> on listening to each and every person who compares with us, so we can make it seriously simple for you<br clas="hidden md:block" /> to save money.
        </p>

        <img
          :src="`${APP_ASSET_BASEURL}edge/static/images/hero/who_are_cc_v2_1x.webp`"
          width="678"
          height="255"
          alt="Who are Compare Club?"
          loading="lazy"
        />

        <div class="grid lg:grid-cols-3 grid-cols-1 gap-8 max-w-6xl m-auto mb-10">
          <lazy-atoms-card
            v-for="(item, itemKey) in whoAreCcItems"
            :key="itemKey"
            size="lg"
            class="text-center px-8 py-6 md:py-10"
            rounded="lg"
            shadow="lg"
          >
            <!-- eslint-disable vue/no-v-html -->
            <p
              v-if="item.title"
              class="text-2xl font-bold"
              v-html="item.title"
            >
            </p>

            <p
              v-if="item.sub_title"
              class="text-xl"
              v-html="item.sub_title"
            >
            </p>

            <p
              v-if="item.description"
              class="text-base md:text-lg mt-4"
              v-html="item.description"
            >
            </p>
          </lazy-atoms-card>
        </div>

        <p class="font-bold text-2xl md:text-2.5xl md:leading-9 mb-4">
          And we’re just getting started...
        </p>

        <p class="text-base md:text-lg">
          Looking for an amazing place to work? Well, we’re looking for awesome people to make us<br class="hidden md:block" />
          the best at what we do.

          <nuxt-link
            to="/careers/"
            class="font-extrabold text-primary underline"
          >
            See current openings
          </nuxt-link>
        </p>
      </div>
    </div>

    <molecules-home-footer-info theme="default">
      <template #title>
        Things you should know
      </template>

      <template #description>
        <p>
          * Our average customer saved $300.43 average savings based on 136,746 customers between 1 Jan 2018 - 23 December 2022.
        </p>

        <p>
          ** Savings are calculated compared to the Energy Reference price for a single-rate energy meter based on Compare Club’s best offer as of 25th July 2024.
        </p>
      </template>
    </molecules-home-footer-info>
  </div>
</template>

<script setup>
import _omit from 'underscore/cjs/omit.js'

const layoutsStore = useLayoutsStore()
const trustpilotStore = useTrustpilotStore()

const navProps = layoutsStore.navigation
navProps.hasShadow = true

defineOptions({
  name: 'Home'
})

const $route = useRoute()

generateMeta({
  title: 'Compare Club: Health & Life Insurance, Energy, Loans & More',
  ogTitle: 'Compare Club: Health & Life Insurance, Energy, Loans & More',
  description: 'Do your household bills need saving? Compare, change and save money on your health insurance, energy bills, home loans and life cover',
  ogDescription: 'Save money on your household expenses. We compare health and life insurance, home loans, energy plans, childcare and much more',
  ogUrl: 'https://compareclub.com.au/',
  ogType: 'homepage'
}, pageIsFunnel($route))

const verticalCard = _omit(VERTICALS, (_, key) => ['Default', 'Car Loans', 'Personal Loans'].includes(key))

const mediaOutlets = computed(() => {
  return [
    {
      url: `${APP_ASSET_BASEURL}images/logos/abc-news.svg`,
      width: 88.99,
      height: 18.99
    },
    {
      url: `${APP_ASSET_BASEURL}images/logos/9news.svg`,
      width: 106,
      height: 18.99
    },
    {
      url: `${APP_ASSET_BASEURL}images/logos/7news.svg`,
      width: 83,
      height: 18.99
    },
    {
      url: `${APP_ASSET_BASEURL}images/logos/kiis.svg`,
      width: 43,
      height: 18.99
    },
    {
      url: `${APP_ASSET_BASEURL}images/logos/hearld-sun.svg`,
      width: 98,
      height: 16
    },
    {
      url: `${APP_ASSET_BASEURL}images/logos/daily-telegraph.svg`,
      width: 98,
      height: 15
    },
    {
      url: `${APP_ASSET_BASEURL}images/logos/courier-mail.svg`,
      width: 98,
      height: 13.99
    },
    {
      url: `${APP_ASSET_BASEURL}images/logos/financial-review.svg`,
      width: 116.01,
      height: 14.3
    }
  ]
})

const savingPlanItems = computed(() => {
  return [
    {
      title: 'Health Insurance',
      description: 'Get the cover you and your <strong>family needs</strong> right now - the basics, family cover or the right level for peace of mind.',
      productPageUrl: VERTICALS[VERTICAL.HEALTH_INSURANCE].productPage
    },
    {
      title: 'Energy',
      description: 'No more electricity <strong>bill shock,</strong> ever. Compare energy plans, switch energy providers and relax. ',
      productPageUrl: VERTICALS[VERTICAL.ENERGY].productPage
    },
    {
      title: 'Life Insurance',
      description: 'Affordable insurance with cover  to suit your budget, life stage & <strong>protect your loved ones\'</strong> financial future.',
      productPageUrl: VERTICALS[VERTICAL.LIFE_INSURANCE].productPage
    },
    {
      title: 'Home Loans',
      description: 'We’ve made a <strong>complex process simple.</strong> We’ll find the best loan to meet your needs from our panel of lenders.',
      productPageUrl: VERTICALS[VERTICAL.HOME_LOANS].productPage
    },
    {
      title: 'Car Insurance',
      description: 'Cut through the confusion and <strong>keep your costs low.</strong> You could switch and save on your car insurance in a matter of minutes.',
      productPageUrl: VERTICALS[VERTICAL.CAR_INSURANCE].productPage
    },
    {
      title: 'Credit Cards',
      description: 'Compare credit cards side by side from St George, Westpac, American Express and more. Hone in on the piece of plastic that give you <strong>most back to your bank balance</strong> and apply online.',
      productPageUrl: VERTICALS[VERTICAL.CREDIT_CARDS].productPage
    },
    {
      title: 'Personal Loans',
      description: 'Let our specialists <strong>take away the stress</strong> of finding an affordable loan. We’ll compare lenders to find a rate that fits your needs.',
      productPageUrl: VERTICALS[VERTICAL.PERSONAL_LOANS].productPage
    },
    {
      title: 'Car Loans',
      description: 'Let our specialists <strong>take away the stress</strong> of finding an affordable loan. We’ll compare lenders to find a rate that fits your needs.',
      productPageUrl: VERTICALS[VERTICAL.CAR_LOANS].productPage
    }
  ]
})

const whoAreCcItems = computed(() => {
  return [
    {
      title: '1million+',
      sub_title: '(that’s 3,000 enquiries per day)',
      description: 'Each year over a million Aussies<br clas="hidden md:block" /> use our expertise to save money,<br clas="hidden md:block" /> find better value deals, reorganise<br clas="hidden md:block" /> their finances, and, ultimately, build<br clas="hidden md:block" /> a better bank balance.'
    },
    {
      title: '10 years<br class="md:block hidden"> young',
      description: 'We’re a young business and<br clas="hidden md:block" /> a start-up at heart! We’ve been<br clas="hidden md:block" /> kicking goals for our customers<br clas="hidden md:block" /> for some time and there’s lots more to come.'
    },
    {
      title: 'The best<br clas="hidden md:block" /> people',
      description: 'We have over 250 experts helping<br clas="hidden md:block" /> people with their finances every<br clas="hidden md:block" /> day. We’re a proudly Australian<br clas="hidden md:block" /> business that helps other Aussies<br clas="hidden md:block" /> make better financial decisions.'
    }
  ]
})

useJsonld(() => {
  return {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    brand: 'Compare Club',
    name: 'Compare Club',
    url: 'https://compareclub.com.au',
    description: 'We\'re experts in saving Australians money on health insurance, home loans, energy bills, and life insurance.',
    address: {
      '@type': 'PostalAddress',
      addressLocality: 'Sydney, Australia',
      postalCode: '2000',
      streetAddress: 'Level 7/222 Pitt St, Sydney NSW'
    },
    logo: 'https://asset.compareclub.com.au/edge/static/images/cc-logo.svg',
    sameAS: ['https://www.facebook.com/compareclubau/', 'https://www.instagram.com/compareclub_au/', 'https://twitter.com/CompareClub/', 'https://au.linkedin.com/company/compare-club/'],
    contactPoint: [
      {
        '@type': 'ContactPoint',
        areaServed: 'Australia',
        contactType: 'Customer Service',
        contactOption: 'TollFree',
        telephone: '+(61)1300 757 819',
        productSupported: 'Health Insurance Comparison'
      }, {
        '@type': 'ContactPoint',
        areaServed: 'Australia',
        contactType: 'Customer Service',
        contactOption: 'TollFree',
        telephone: '+(61)1300 598 418',
        productSupported: 'Life Insurance Comparison'
      }, {
        '@type': 'ContactPoint',
        areaServed: 'Australia',
        contactType: 'Customer Service',
        contactOption: 'TollFree',
        telephone: '+(61)1300 190 429',
        productSupported: 'Home Loan Comparison'
      }],
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: trustpilotStore.trustpilotData?.trustScore,
      bestRating: '5',
      worstRating: '1',
      ratingCount: trustpilotStore.trustpilotData?.numberOfReviews.total
    }
  }
})
</script>
